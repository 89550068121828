import { createRouter, createWebHashHistory } from 'vue-router'
import { StorageSerializers, useStorage } from '@vueuse/core'
import { Ability } from '@casl/ability'
import {
  authGuard,
  checkIdCompany,
  companyGuard,
  guardChain,
  noAuthGuard,
} from './common/utils/routing.utils'
import { breadcrumbs } from '@/common/constants/breadcrumb.constant'
import useAuthentication from '@/composables/useAuthentication'
import store from '@/store'

const routes = [
  {
    path: '/',
    name: 'root',
    beforeEnter: () => {
      const { isLoggedIn, currentCompany } = useAuthentication()

      if (isLoggedIn.value === true && currentCompany.value?._id) {
        return {
          name: 'dashboard',
          params: { idCompany: currentCompany.value._id },
        }
      } else {
        return { name: 'login' }
      }
    },
  },
  {
    path: '/login/:message?',
    name: 'login',
    component: () => import('./pages/SignIn.vue'),
    beforeEnter: noAuthGuard,
  },
  {
    path: '/account/pending-approval',
    name: 'pending_approval',
    component: () => import('./pages/PendingApproval.vue'),
    // beforeEnter: noAuthGuard,
  },
  {
    path: '/access-denied',
    name: 'access-denied',
    component: () => import('./pages/AccessDenied'),
  },
  {
    path: '/account/initialization',
    name: 'initialization',
    component: () => import('./pages/account/Initialization.vue'),
    // beforeEnter: noAuthGuard,
  },
  {
    path: '/:idCompany',
    beforeEnter: guardChain([authGuard, checkIdCompany]),
    children: [
      {
        path: '',
        name: 'dashboard',
        exact: true,
        beforeEnter: guardChain([authGuard, checkIdCompany]),
        redirect: () => {
          let authData = store.getters['auth/authData']

          if (!authData) {
            authData = useStorage('AUTH_DATA', null)
          }

          const currentUser = authData?.user

          return {
            name: `dashboard-${currentUser?.defaultDashboard || 'main'}`,
          }
        },
        component: () => import('./pages/DashboardPage'),
        children: [
          {
            path: 'dashboard-main',
            name: 'dashboard-main',
            exact: true,
            component: () => import('./pages/DashboardMain'),
            meta: {
              breadcrumb: breadcrumbs.DASHBOARD,
            },
            beforeEnter: guardChain([authGuard, checkIdCompany]),
          },
          {
            path: 'dashboard-assignee',
            name: 'dashboard-assignee',
            exact: true,
            component: () => import('./pages/DashboardAssignee'),
            meta: {
              breadcrumb: breadcrumbs.DASHBOARD,
            },
            beforeEnter: guardChain([authGuard, checkIdCompany]),
          },
          {
            path: 'dashboard-sample',
            name: 'dashboard-sample',
            exact: true,
            component: () => import('./components/Dashboard.vue'),
            meta: {
              breadcrumb: breadcrumbs.DASHBOARD,
            },
            beforeEnter: guardChain([authGuard, checkIdCompany]),
          },
          {
            path: 'dashboard-biller',
            name: 'dashboard-biller',
            exact: true,
            component: () => import('./pages/DashboardBiller.vue'),
            meta: {
              breadcrumb: breadcrumbs.DASHBOARD,
            },
            beforeEnter: guardChain([authGuard, checkIdCompany]),
          },
          {
            path: 'dashboard-budget',
            name: 'dashboard-budget',
            exact: true,
            component: () => import('./pages/DashboardBudget.vue'),
            meta: {
              breadcrumb: breadcrumbs.DASHBOARD,
            },
            beforeEnter: guardChain([authGuard, checkIdCompany]),
          },
        ],
      },
      {
        path: 'management/user',
        name: 'user_management',
        exact: true,
        component: () => import('./pages/management/User.vue'),
        props: {
          reportComponent: 'user-report',
        },
        meta: {
          breadcrumb: breadcrumbs.USER,
          permissions: {
            User: 'read',
          },
        },
        beforeEnter: guardChain([authGuard, checkIdCompany]),
      },
      {
        path: 'management/company',
        name: 'company_management',
        exact: true,
        component: () => import('./pages/management/Company.vue'),
        meta: {
          breadcrumb: breadcrumbs.COMPANY,
          permissions: {
            Company: 'read',
          },
        },
        beforeEnter: guardChain([authGuard, checkIdCompany]),
      },
      {
        path: 'management/currency',
        name: 'currency_management',
        exact: true,
        component: () => import('./pages/management/Currency.vue'),
        meta: {
          breadcrumb: breadcrumbs.CURRENCY,
          permissions: {
            Currency: 'read',
          },
        },
        beforeEnter: guardChain([authGuard, checkIdCompany]),
      },
      {
        path: 'management/local-product-type',
        name: 'local_product_type_management',
        exact: true,
        component: () => import('./pages/management/LocalProductType.vue'),
        meta: {
          breadcrumb: breadcrumbs.LOCAL_PRODUCT_TYPE,
          permissions: {
            LocalProductType: 'read',
          },
        },
        beforeEnter: guardChain([authGuard, checkIdCompany]),
      },
      {
        path: 'management/quickbook',
        name: 'quickbook_management',
        exact: true,
        component: () => import('./pages/management/Quickbook.vue'),
        meta: {
          breadcrumb: breadcrumbs.QUICKBOOK,
          permissions: {
            Quickbooks: 'read',
          },
        },
        beforeEnter: guardChain([authGuard, checkIdCompany]),
      },
      {
        path: 'management/entity-billing-software',
        name: 'entity_billing_software_management',
        exact: true,
        component: () => import('./pages/management/EntityBillingSoftware.vue'),
        meta: {
          breadcrumb: breadcrumbs.LOCAL_SOFTWARE,
          permissions: {
            EntityBillingSoftware: 'read',
          },
        },
        beforeEnter: guardChain([authGuard, checkIdCompany]),
      },
      {
        path: 'management/role',
        name: 'role_management',
        exact: true,
        component: () => import('./pages/management/Role.vue'),
        meta: {
          breadcrumb: breadcrumbs.ROLE,
          permissions: {
            Role: 'read',
          },
        },
        beforeEnter: guardChain([authGuard, checkIdCompany]),
      },
      {
        path: 'management/sector',
        name: 'sector_management',
        exact: true,
        component: () => import('./pages/management/Sector.vue'),
        meta: {
          breadcrumb: breadcrumbs.SECTOR,
          permissions: {
            Sector: 'read',
          },
        },
        beforeEnter: guardChain([authGuard, checkIdCompany]),
      },
      {
        path: 'management/client',
        name: 'client_management',
        exact: true,
        component: () => import('./pages/management/Client.vue'),
        props: {
          reportComponent: 'client-report',
        },
        meta: {
          breadcrumb: breadcrumbs.CLIENT,
          permissions: {
            Client: 'read',
          },
        },
        beforeEnter: guardChain([authGuard, checkIdCompany]),
      },
      {
        path: 'management/product',
        name: 'product_management',
        exact: true,
        component: () => import('./pages/management/Product.vue'),
        meta: {
          breadcrumb: breadcrumbs.PRODUCT,
          permissions: {
            Product: 'read',
          },
        },
        beforeEnter: guardChain([authGuard, checkIdCompany]),
      },
      {
        path: 'management/operating-zone',
        name: 'operatingZone_listing',
        component: () => import('./pages/management/OperatingZone.vue'),
        exact: true,
        meta: {
          breadcrumb: breadcrumbs.OPERATING_ZONE,
          permissions: {
            OperatingZone: 'read',
          },
        },
        beforeEnter: guardChain([authGuard, checkIdCompany]),
      },
      {
        path: 'management/external-third-parties',
        name: 'externalThirdParties_listing',
        component: () => import('./pages/management/ExternalThirdParties.vue'),
        exact: true,
        meta: {
          breadcrumb: breadcrumbs.EXTERNAL_THIRD_PARTIES,
          permissions: {
            ExternalThirdParties: 'read',
          },
        },
        beforeEnter: guardChain([authGuard, checkIdCompany]),
      },
      {
        path: 'management/client-provider',
        name: 'clientProvider_listing',
        component: () => import('./pages/management/ClientProvider'),
        exact: true,
        meta: {
          breadcrumb: breadcrumbs.CLIENT_PROVIDER,
          permissions: {
            ClientProvider: 'read',
          },
        },
        beforeEnter: guardChain([authGuard, checkIdCompany]),
      },
      {
        path: 'management/templates',
        name: 'templates',
        exact: true,
        component: () => import('./pages/management/Templates.vue'),
        meta: {
          breadcrumb: breadcrumbs.TEMPLATE,
          permissions: {
            Template: 'read',
          },
        },
        beforeEnter: guardChain([authGuard, checkIdCompany]),
      },
      {
        path: 'management/import-templates',
        name: 'import_template',
        exact: true,
        component: () => import('./pages/management/ImportTemplate.vue'),
        meta: {
          breadcrumb: breadcrumbs.IMPORT_TEMPLATE,
          permissions: {
            ImportTemplate: 'read',
          },
        },
        beforeEnter: guardChain([authGuard, checkIdCompany]),
      },
      {
        path: 'management/platform',
        name: 'platform_management',
        exact: true,
        component: () => import('./pages/management/Platform.vue'),
        meta: {
          breadcrumb: breadcrumbs.PLATFORM,
          permissions: {
            Platform: 'read',
          },
        },
        beforeEnter: guardChain([authGuard, checkIdCompany]),
      },
      {
        path: 'management/reference-exchange-rates',
        name: 'reference_exchange_rates_management',
        exact: true,
        component: () => import('./pages/management/ReferenceExchangeRate.vue'),
        meta: {
          breadcrumb: breadcrumbs.REFERENCE_EXCHANGE_RATES,
        },
        beforeEnter: guardChain([authGuard, checkIdCompany]),
      },
      {
        path: 'management/interco',
        name: 'interco',
        component: () => import('./pages/management/Interco.vue'),
        meta: {
          breadcrumb: breadcrumbs.INTERCO,
        },
        props: {
          creatable: false,
          noAction: true,
        },
        beforeEnter: guardChain([authGuard, checkIdCompany]),
      },
      {
        path: 'management/jedox-reporting-line',
        name: 'jedox_reporting_line',
        component: () => import('./pages/management/JedoxReportingLine.vue'),
        meta: {
          breadcrumb: breadcrumbs.JEDOX_REPORTING_LINE,
        },
        beforeEnter: guardChain([authGuard, checkIdCompany]),
      },
      {
        path: 'management/tag',
        name: 'tag_listing',
        component: () => import('./pages/management/Tag.vue'),
        exact: true,
        meta: {
          breadcrumb: breadcrumbs.TAG,
          permissions: {
            Tag: 'read',
          },
        },
        props: {
          companyScoped: true,
        },
        beforeEnter: guardChain([authGuard, checkIdCompany]),
      },
      {
        path: 'management/local-client',
        name: 'local_client_management_listing',
        exact: true,
        component: () =>
          import('./pages/management/local_client/LocalClient.vue'),
        props: {
          reportComponent: 'local-client-report',
          companyScoped: true,
        },
        meta: {
          breadcrumb: breadcrumbs.LOCAL_CLIENT,
          permissions: {
            LocalClient: 'read',
          },
        },
        beforeEnter: guardChain([authGuard, companyGuard, checkIdCompany]),
      },
      {
        path: 'management/local-client/:id',
        name: 'local_client_management',
        exact: true,
        meta: {
          permissions: {
            LocalClient: 'read',
          },
        },
        children: [
          {
            path: 'information',
            name: 'local_client_management_information',
            component: () =>
              import(
                './pages/management/local_client/LocalClientInformation.vue'
              ),
            props: true,
            beforeEnter: guardChain([authGuard, checkIdCompany]),
          },
          {
            path: 'productions',
            name: 'local_client_management_productions',
            component: () =>
              import(
                './pages/management/local_client/LocalClientProductions.vue'
              ),
            props: true,
            beforeEnter: guardChain([authGuard, checkIdCompany]),
          },
        ],
        beforeEnter: guardChain([authGuard, companyGuard, checkIdCompany]),
      },
      {
        path: 'management/contact',
        name: 'contact_management_listing',
        exact: true,
        component: () => import('./pages/management/contact/Contact.vue'),
        props: {
          companyScoped: true,
          reportComponent: 'contact-report',
        },
        meta: {
          breadcrumb: breadcrumbs.CONTACT,
          permissions: {
            Contact: 'read',
          },
        },
        beforeEnter: guardChain([authGuard, companyGuard, checkIdCompany]),
      },
      {
        path: 'management/contact/:id',
        name: 'contact_management',
        exact: true,
        component: () => import('./pages/management/contact/ContactMain.vue'),
        meta: {
          permissions: {
            Contact: 'read',
          },
        },
        children: [
          {
            path: 'information',
            name: 'contact_management_information',
            component: () =>
              import('./pages/management/contact/ContactInformation.vue'),
            beforeEnter: guardChain([authGuard, checkIdCompany]),
          },
        ],
        beforeEnter: guardChain([authGuard, companyGuard, checkIdCompany]),
      },
      {
        path: 'management/program-management',
        name: 'program_management_listing',
        component: () =>
          import('./pages/management/program_management/ProgramManagement.vue'),
        exact: true,
        meta: {
          breadcrumb: breadcrumbs.PROGRAM_MANAGEMENT,
          permissions: {
            ProgramManagement: 'read',
          },
        },
        props: {
          companyScoped: true,
        },
        beforeEnter: guardChain([authGuard, checkIdCompany]),
      },
      {
        path: 'management/program-management/:id',
        name: 'program_management_edition',
        exact: true,
        meta: {
          permissions: {
            ProgramManagement: 'update',
          },
        },
        component: () =>
          import(
            './pages/management/program_management/ProgramManagementEdition.vue'
          ),
        beforeEnter: guardChain([authGuard, checkIdCompany]),
      },
      {
        path: 'management/program-management/:id/information',
        name: 'program_management_information',
        exact: true,
        meta: {
          permissions: {
            ProgramManagement: 'read',
          },
        },
        component: () =>
          import(
            './pages/management/program_management/ProgramManagementInformation.vue'
          ),
        beforeEnter: guardChain([authGuard, checkIdCompany]),
      },
      {
        path: 'management/contract',
        name: 'contract_management',
        exact: true,
        component: () => import('./pages/management/Contract.vue'),
        props: {
          companyScoped: true,
        },
        meta: {
          breadcrumb: breadcrumbs.CONTRACT,
          permissions: {
            Contract: 'read',
          },
        },
        beforeEnter: guardChain([authGuard, companyGuard, checkIdCompany]),
      },
      {
        path: 'management/local-product',
        name: 'local_product_management',
        exact: true,
        component: () => import('./pages/management/LocalProduct.vue'),
        meta: {
          breadcrumb: breadcrumbs.LOCAL_PRODUCT,
          permissions: {
            LocalProduct: 'read',
          },
        },
        props: {
          companyScoped: true,
          reportComponent: 'local-product-report',
        },
        beforeEnter: guardChain([authGuard, checkIdCompany]),
      },
      {
        path: 'management/services',
        name: 'services',
        exact: true,
        component: () => import('./pages/management/Services.vue'),
        meta: {
          breadcrumb: breadcrumbs.SERVICES,
          permissions: {
            Services: 'read',
          },
        },
        props: {
          creatable: false,
          companyScoped: true,
          noAction: true,
        },
        beforeEnter: guardChain([authGuard, checkIdCompany]),
      },
      {
        path: 'management/production',
        name: 'production',
        exact: true,
        component: () => import('./pages/management/Production.vue'),
        meta: {
          breadcrumb: breadcrumbs.PRODUCTION,
          permissions: {
            Production: 'read',
          },
        },
        props: {
          companyScoped: true,
        },
        beforeEnter: guardChain([authGuard, checkIdCompany]),
      },
      {
        path: 'management/external-production',
        name: 'externalproduction',
        exact: true,
        component: () => import('./pages/management/ExternalProduction.vue'),
        meta: {
          breadcrumb: breadcrumbs.EXTERNAL_PRODUCTION,
          permissions: {
            ExternalProduction: 'read',
          },
        },
        props: {
          companyScoped: true,
        },
        beforeEnter: guardChain([authGuard, checkIdCompany]),
      },
      {
        path: 'management/external-post-production',
        name: 'external_post_production',
        exact: true,
        component: () =>
          import('./pages/management/ExternalPostProduction.vue'),
        meta: {
          breadcrumb: breadcrumbs.EXTERNAL_POST_PRODUCTION,
        },
        props: {
          companyScoped: true,
        },
        beforeEnter: guardChain([authGuard, checkIdCompany]),
      },
      {
        path: 'management/postproduction',
        name: 'postproduction',
        exact: true,
        component: () => import('./pages/management/PostProduction.vue'),
        meta: {
          breadcrumb: breadcrumbs.POSTPRODUCTION,
          permissions: {
            Postproduction: 'read',
          },
        },
        props: {
          companyScoped: true,
        },
        beforeEnter: guardChain([authGuard, checkIdCompany]),
      },
      {
        path: 'management/prebilling',
        name: 'prebilling',
        exact: true,
        component: () => import('./pages/management/PreBilling.vue'),
        meta: {
          breadcrumb: breadcrumbs.PREBILLING,
          permissions: {
            Prebilling: 'read',
          },
        },
        props: {
          companyScoped: true,
        },
        beforeEnter: guardChain([authGuard, checkIdCompany]),
      },
      {
        path: 'management/sales',
        name: 'sales',
        exact: true,
        component: () => import('./pages/management/sales/Sales.vue'),
        meta: {
          breadcrumb: breadcrumbs.SALES,
          permissions: {
            Sales: 'read',
          },
        },
        props: {
          companyScoped: true,
        },
        beforeEnter: guardChain([authGuard, checkIdCompany]),
      },
      {
        path: 'management/real/pivot-table',
        name: 'real',
        exact: true,
        component: () => import('./pages/management/Real.vue'),
        meta: {
          breadcrumb: breadcrumbs.REAL,
          permissions: {
            Real: 'read',
          },
        },
        props: {
          companyScoped: true,
        },
        beforeEnter: guardChain([authGuard, checkIdCompany]),
      },
      {
        path: 'management/internal-costs',
        name: 'internalCost',
        exact: true,
        component: () => import('./pages/management/InternalCost.vue'),
        meta: {
          breadcrumb: breadcrumbs.INTERNAL_COST,
          permissions: {
            InternalCost: 'read',
          },
        },
        props: {
          companyScoped: true,
        },
        beforeEnter: guardChain([authGuard, checkIdCompany]),
      },
      {
        path: 'management/productions',
        name: 'productions',
        component: () =>
          import('./pages/management/production/ProductionList.vue'),
        meta: {
          breadcrumb: breadcrumbs.PRODUCTIONS,
          permissions: {
            Production: 'read',
          },
        },
        beforeEnter: guardChain([authGuard, checkIdCompany]),
      },
      {
        path: 'management/fee/:id/:date',
        name: 'fee',
        component: () => import('./pages/management/fee/FeeItem.vue'),
        meta: {
          breadcrumb: breadcrumbs.FEE,
        },
        beforeEnter: guardChain([authGuard, checkIdCompany]),
        props: true,
        children: [
          {
            path: 'production/:productionId',
            name: 'fee_production',
            component: () =>
              import('./pages/management/fee/FeeItemProduction.vue'),
            meta: {
              breadcrumb: [...breadcrumbs.FEE],
            },
            beforeEnter: guardChain([authGuard, checkIdCompany]),
            props: true,
          },
        ],
      },

      {
        path: 'management/production/:id',
        name: 'production_management',
        component: () =>
          import('./pages/management/production/ProductionItem.vue'),
        meta: {
          breadcrumb: breadcrumbs.PRODUCTION,
          permissions: {
            Production: 'read',
          },
        },
        beforeEnter: guardChain([authGuard, checkIdCompany]),
        props: true,
        children: [
          {
            path: 'post-production',
            name: 'production_post-production',
            component: () =>
              import(
                './pages/management/production/ProductionItemPostProduction'
              ),
            meta: {
              productionState: 'post-production',
              breadcrumb: [...breadcrumbs.POSTPRODUCTION],
            },
            beforeEnter: guardChain([authGuard, checkIdCompany]),
            props: true,
          },
          {
            path: 'pre-billing',
            name: 'production_pre-billing',
            component: () =>
              import('./pages/management/production/ProductionItemPreBilling'),
            meta: {
              productionState: 'pre-billing',
              breadcrumb: [...breadcrumbs.PREBILLING],
            },
            beforeEnter: guardChain([authGuard, checkIdCompany]),
            props: true,
          },
        ],
      },
      {
        path: 'management/simple-projects',
        name: 'simple_projects_listing',
        component: () =>
          import('./pages/management/simple_project/SimpleProject.vue'),
        exact: true,
        meta: {
          breadcrumb: breadcrumbs.SIMPLE_PROJECT,
          permissions: {
            Project: 'read',
          },
        },
        props: {
          companyScoped: true,
        },
        beforeEnter: guardChain([authGuard, checkIdCompany]),
      },
      {
        path: 'management/simple-projects/:id',
        name: 'simple_projects_edition',
        component: () =>
          import('./pages/management/simple_project/SimpleProjectEdition'),
        meta: {
          permissions: {
            Project: ['read', 'update'],
          },
        },
        props: true,
        beforeEnter: guardChain([authGuard, checkIdCompany]),
      },
      {
        path: 'management/internal-projects',
        name: 'internal_projects_listing',
        component: () =>
          import('./pages/management/internal_project/InternalProject.vue'),
        exact: true,
        meta: {
          breadcrumb: breadcrumbs.INTERNAL_PROJECT,
          permissions: {
            InternalProject: 'read',
          },
        },
        props: {
          companyScoped: true,
        },
        beforeEnter: guardChain([authGuard, checkIdCompany]),
      },
      {
        path: 'management/internal-projects/:id',
        name: 'internal_projects_edition',
        component: () =>
          import('./pages/management/internal_project/InternalProjectEdition'),
        meta: {
          permissions: {
            InternalProject: ['read', 'update'],
          },
        },
        props: true,
        beforeEnter: guardChain([authGuard, checkIdCompany]),
      },
      {
        path: 'management/declination-master-product',
        name: 'declination_master_product',
        exact: true,
        component: () =>
          import('./pages/management/DeclinationMasterProduct.vue'),
        meta: {
          breadcrumb: breadcrumbs.DECLINATION_MASTER_PRODUCTS,
          permissions: {
            DeclinationMasterProduct: 'read',
          },
        },
        props: {
          companyScoped: true,
        },
        beforeEnter: guardChain([authGuard, checkIdCompany]),
      },
      {
        path: 'management/budget',
        name: 'budget',
        component: () => import('./pages/management/budget/Budget.vue'),
        exact: true,
        meta: {
          breadcrumb: breadcrumbs.BUDGET,
          permissions: {
            Budget: 'read',
          },
        },
        beforeEnter: guardChain([authGuard, checkIdCompany]),
      },
      {
        path: 'management/budget/all',
        name: 'budgets',
        component: () => import('./pages/management/budget/Budget.vue'),
        exact: true,
        props: {
          action: 'show_budget_entries',
        },
        meta: {
          breadcrumb: breadcrumbs.BUDGET,
        },
        beforeEnter: guardChain([authGuard, checkIdCompany]),
      },
      {
        path: 'management/budget/:id',
        name: 'budget_management',
        exact: true,
        children: [
          {
            path: 'information',
            name: 'budget_information',
            component: () => import('./pages/management/budget/BudgetItem.vue'),
            beforeEnter: guardChain([authGuard, checkIdCompany]),
          },
        ],
        beforeEnter: guardChain([authGuard, companyGuard, checkIdCompany]),
      },
      {
        path: 'management/budget/:id/budget-entries',
        name: 'budget_entries_filtered_by_budget',
        component: () => import('./pages/management/budget/BudgetEntries.vue'),
        exact: true,
        meta: {
          breadcrumb: breadcrumbs.BUDGET_ENTRIES,
          permissions: {
            Budget: 'update',
          },
        },
        beforeEnter: guardChain([authGuard, checkIdCompany]),
      },
      {
        path: 'management/budget-entries',
        name: 'budget_entries',
        component: () => import('./pages/management/budget/BudgetEntries.vue'),
        exact: true,
        meta: {
          breadcrumb: breadcrumbs.BUDGET_ENTRIES,
          permissions: {
            Budget: 'read',
          },
        },
        beforeEnter: guardChain([authGuard, checkIdCompany]),
      },
      {
        path: 'management/budget-dashboard',
        name: 'budget_dashboard',
        exact: true,
        meta: {
          breadcrumb: breadcrumbs.BUDGET_DASHBOARD,
          permissions: {
            Budget: 'read',
          },
        },
        beforeEnter: guardChain([authGuard, checkIdCompany]),
        redirect: () => {
          return {
            name: `budget_pm`,
          }
        },
        component: () =>
          import('./pages/management/budget/BudgetDashboard.vue'),
        children: [
          {
            path: 'budget-pm',
            name: 'budget_pm',
            component: () => import('./pages/management/budget/BudgetPM.vue'),
            exact: true,
            meta: {
              breadcrumb: breadcrumbs.BUDGET_DASHBOARD,
            },
            beforeEnter: guardChain([authGuard, checkIdCompany]),
          },
          {
            path: 'budget-ps',
            name: 'budget_ps',
            component: () => import('./pages/management/budget/BudgetPS.vue'),
            exact: true,
            meta: {
              breadcrumb: breadcrumbs.BUDGET_DASHBOARD,
            },
            beforeEnter: guardChain([authGuard, checkIdCompany]),
          },
          {
            path: 'budget-pi',
            name: 'budget_pi',
            component: () => import('./pages/management/budget/BudgetPI.vue'),
            exact: true,
            meta: {
              breadcrumb: breadcrumbs.BUDGET_DASHBOARD,
            },
            beforeEnter: guardChain([authGuard, checkIdCompany]),
          },
          {
            path: 'budget-recharge',
            name: 'budget_recharge',
            component: () =>
              import('./pages/management/budget/BudgetRecharge.vue'),
            exact: true,
            meta: {
              breadcrumb: breadcrumbs.BUDGET_DASHBOARD,
            },
            beforeEnter: guardChain([authGuard, checkIdCompany]),
          },
          {
            path: 'jedox-reconciliation',
            name: 'jedox_reconciliation',
            component: () =>
              import('./pages/management/budget/BudgetWithJedox.vue'),
            exact: true,
            meta: {
              breadcrumb: breadcrumbs.BUDGET_DASHBOARD,
            },
            beforeEnter: guardChain([authGuard, checkIdCompany]),
          },
        ],
      },
      {
        path: 'management/logging',
        name: 'logging',
        exact: true,
        component: () => import('./pages/management/Logging.vue'),
        meta: {
          breadcrumb: breadcrumbs.LOGGING,
          permissions: {
            Logging: 'read',
          },
        },
        props: {
          companyScoped: true,
        },
        beforeEnter: guardChain([authGuard, checkIdCompany]),
      },
      {
        path: 'management/api-tokens',
        name: 'api_tokens_management',
        exact: true,
        component: () => import('./pages/management/ApiToken.vue'),
        meta: {
          breadcrumb: breadcrumbs.API_TOKEN,
          permissions: {
            ApiToken: 'read',
          },
        },
        beforeEnter: guardChain([authGuard]),
      },
      {
        path: 'management/client-advertisers',
        name: 'client_advertiser',
        exact: true,
        component: () => import('./pages/management/ClientAdvertiser.vue'),
        meta: {
          breadcrumb: breadcrumbs.CLIENT_ADVERTISER,
          permissions: {
            ClientAdvertiser: 'read',
          },
        },
        beforeEnter: guardChain([authGuard, checkIdCompany]),
      },

      {
        path: 'management/features',
        name: 'features_management',
        exact: true,
        component: () => import('./pages/management/feature/Feature.vue'),
        meta: {
          breadcrumb: breadcrumbs.FEATURE,
          permissions: {
            Feature: 'read',
          },
        },
        beforeEnter: guardChain([authGuard]),
      },
      {
        path: 'management/all-features',
        name: 'all_features_management',
        exact: true,
        component: () => import('./pages/management/feature/AllFeature.vue'),
        meta: {
          breadcrumb: breadcrumbs.FEATURE,
          permissions: {
            Feature: 'read',
          },
        },
        beforeEnter: guardChain([authGuard]),
      },
      {
        path: 'management/scripts-runner',
        name: 'scripts_runner',
        exact: true,
        component: () =>
          import('./pages/management/script_runner/ScriptRunner.vue'),
        meta: {
          breadcrumb: breadcrumbs.SCRIPT_RUNNER,
          permissions: {
            ScriptRunner: 'read',
          },
        },
        beforeEnter: guardChain([authGuard]),
      },
    ],
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  scrollBehavior() {
    return { left: 0, top: 0 }
  },
})

router.beforeEach((to) => {
  const authData = useStorage('AUTH_DATA', null, undefined, {
    serializer: StorageSerializers.object,
  })
  const abilityRules = authData.value?.user?.ability_rules
  const permissions = to.meta?.permissions

  if (!abilityRules || !permissions) return

  const ability = new Ability(abilityRules)

  for (const [resource, action] of Object.entries(permissions)) {
    const actions = Array.isArray(action) ? action : [action]

    if (actions.every((action) => ability.cannot(action, resource))) {
      return { name: 'access-denied' }
    }
  }
})

export default router
